.search-input-container {
	display: flex;
	align-items: center;
	border: solid 1px #e6eded;
	border-radius: 4px;
	background: #fff;
	padding: 8px 12px;
	font-size: 14px;

	input {
		flex: 1 1 auto;
		background: transparent;
		outline: 0;
		border: 0;
		box-shadow: none;
		color: #4f4e4e;
	}
}