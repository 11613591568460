.incidents-list {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    // max-width: 580px;

    .incidents-list-inner {
        overflow: auto;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        padding: 0 8px 0 8px;
    }
}

.incident-card {
    font-size: medium;
    line-height: normal;
    border-bottom: 1px solid var(--surface-light);
    border: solid 1px var(--color-turquoise-light);
    border-radius: 4px;
    margin-top: 10px;

    .incident-icon-wrapper {
        height: unset;
        flex: 0 0 auto;

        svg.icon-base.main-icon {
            width: 40px;
            height: 40px;
        }
    }

    .section {
        display: flex;
    }

    .fields-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding: 8px 8px 4px 16px;
        font-size: small;
        overflow: hidden;

        div:last-child {
            display: flex;
            align-items: center;
            color: rgba(79, 78, 78, 0.5);
            font-size: smaller;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-top: 8px;
        }
    }

    .title {
        font-weight: 600;
        font-size: medium;
    }

    .sub-title {
        font-weight: 500;
    }

    .right-side {
        display: flex;
        flex-direction: column;
        padding: 4px 4px;

        .right-side-section {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .text-small {
        flex: 1 1 50%;
    }

    .text-separator {
        margin: 0 6px;
        font-size: 8px;
    }
}

.bottom-widget {
    padding: 8px;
    text-align: center;
}