.search-with-autocomplete {
    width: 250px;
    height: 36px;
    
    input {
		box-shadow: none;
	}

    img {
        margin-right: 5px;
    }
}
