.basic-menu {
    display: flex;
    align-items: center;
    color: var(--color-green-sea, rgb(22, 112, 92));

    .icon-base {
        fill: var(--color-green-sea, rgb(22, 112, 92));
    }

    button {
        background-color: transparent;
        border: 1px solid transparent;
        transition: all 0.3s;
        color: inherit;

        .button-label {
            display: inline-flex;
            align-items: center;
            padding: 0 8px 0 0;
            border-right: 1px solid transparent;
            vertical-align: bottom;
            gap: 0.8em;
        }

        &:hover,
        &.show {
            transition: all 0.3s;
            border: 1px solid var(--color-green-sea, rgb(22, 112, 92));
            background-color: var(--color-green-sea, rgb(22, 112, 92));
            color: white;

            .icon-base {
                fill: white;
            }
        }
    }

    .dropdown-menu {
        border-radius: 5.1px;
        box-shadow: 3.9px 3.9px 2.6px 0 rgba(0, 0, 0, 0.25);
        border: solid 0.6px #18715e;
    }

    .dropdown-item {
        padding-top: 0;
        padding-bottom: 0;

        &:active,
        &.active {
            background-color: var(--primary, gray);
        }
    }    
}