.filter-info {
    font-size: 16px;
    color: #344054;
    font-weight: bold;
    padding: 0 0 0 0;

    .primary {
        color: #6941c6;
    }

    .action-btn {
        color: rgba(79, 78, 78, 0.5);
        margin-left: 24px;
        cursor: pointer;
        text-decoration: underline;
        transition: color 0.3s;

        &:hover {
            color: #6941c6;
        }

        &:active {
            opacity: 0.8;
        }
    }
}