.create-manual-event {

    .block-title {
        margin-bottom: 8px;
    }

    .modal-block {
        background: #fff;
        border: none;
        box-shadow: 0 0 20px 0 rgba(79, 78, 78, 0.2);
        padding: 18px;
        .modal-block-content {
            margin: 0;
        }
    }

    .modal-info-row {
        gap: unset;
        align-items: center;
        min-width: 280px;

        .info-row-label {
            flex: 0 0 44%;
        }

        .info-row-value {
            flex: 0 0 56%;
        }
    }

}


.popover.create-manual-event {
    border: none;
    background: transparent;
    
    .manual-event-footer {
        display: flex;
        justify-content: space-evenly;
        margin-top: 8px;

        .button-v1 {
            min-width: 110px;
            &:first-child {
                margin-right: 12px;
            }
        }
    }
}

.button-v1,
.button-v1.disabled {
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    border: solid 1px #d0d5dd;
    background: #fff;
    color: var(--text-surface);

    &:hover:not(:disabled,.disabled) {
        color: #fff;
        background: #6941C6;
        border-color: #6941C6;
    }
}