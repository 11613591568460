.filter-container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	border: 1px solid var(--border-colour);
	border-radius: 6px;

	.filter-trigger {
		font-size: 16px;
		color: var(--text-surface, #4f4e4e);
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		padding: 4px 4px;
		border-left: 1px solid var(--border-colour);

		span.icon {
			height: 14px;
			width: 12px;
			margin: 0 4px;
			background-color: var(--text-surface, #4f4e4e);

			&.filter {
				mask-image: url("/assets/svg/filter.svg");
			}
		}
	}
}
