.collapsible-container {

	.collapse-area {
		transition: all 300ms;
	}

	.collapse-bar {
		text-align: end;
		height: 28px;
	}

	.collapse-trigger {
		right: 0;
		bottom: 0;
		display: inline-flex;
		border: 2px solid #4f4e4e;
		border-radius: 50%;
		background: #fff;
		cursor: pointer;

		&:active {
			opacity: 0.8;
		}

		svg.icon-base {
			fill: #4f4e4e;
		}
	}

}