.empty-trips-list-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    color: gray;
    justify-content: center;
    gap: 2em;

    > div {
        display: flex;
        justify-content: center;
        span {
            font-size: 52px;
            font-weight: 600;
        }
    }
}

.trips-list-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #4f4e4e;

    .text-large {
        font-size: 26px;
        font-weight: 600;
        color: #344054;
    }

    .text-v1 {
        font-weight: 500;
        color: #344054;
    }

    .text-v2 {
        color: #4f4e4e;
    }

    .text-v3 {
        font-size: 15px;
        line-height: 1.6;
        color: rgba(79, 78, 78, 0.8);
    }

    .text-small {
        font-size: 13px;
        color: rgba(79, 78, 78, 0.5);
    }
}

.trips-card-container {
    display: flex;
    border-radius: 4px;
    width: 100%;
    border: solid 1px rgba(79, 78, 78, 0.5);
    background: #fff;
    padding: 8px;
    overflow: hidden;

    >div:first-child {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        align-items: flex-start;
        justify-content: center;
        overflow: hidden;
    }

    >div:last-child {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;
        padding: 0 0 0 4px;
        overflow: hidden;
    }
}

.card-row {
    text-align: start;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;

    svg.icon-base {
        margin-right: 8px;
    }
}