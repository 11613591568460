.input {
	.modal-body {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;

		.dialog-text{
			margin: 10px 15px 20px 15px;
		}

		.text-input-container {
			flex-direction: row;
			gap: 30px;
			padding: 0 20px;
			width: 100%;

			label {
				color: black;
			}
			.pf-input {
				flex-grow: 1;
			}
		}
	}
}