.gallery-modal {
	.modal-dialog {
        width: 60%;
    
        .modal-body{
            min-height: 400px;
            padding: 8px 8px 8px 3px;
        }
	}

    .image-gallery {
        min-height: 400px;
        flex-grow: 1;
    }
    .image-gallery-slide {
        min-height: 400px;
        max-height: 600px;
    }
    .image-gallery-slide-wrapper {
        &:hover {
            .image-gallery-delete-button {
                display: flex;
            }
            .gallery-current-text {
                display: flex;
            }
        }
    }

    .gallery-current-text {
        position: absolute;
        left: 20px;
        bottom: 20px;
        z-index: 1;
        display: none;
        padding: 8px;
        color: white;
        background-color: rgba(0,0,0,0.6);
        padding: 15px;
        font-size: 16px;
        font-family: Inter;
    }

    .image-gallery-delete-button {
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 1;
        cursor: pointer;
        display: none;
        fill: #fff;
        filter: drop-shadow(0 2px 2px #1a1a1a);
        transition: all 0.3s ease-out;
        
        &:hover {
            fill: #337ab7;
        }
    }    
}
