.map-modal {
    .modal-dialog {
        width: 70%;

        .map-container {
            border: solid 1px rgba(0, 100, 78, 0.2);
            aspect-ratio: 16/9;
            border-radius: 6px;
        }

        div.map-legend {
            position: absolute;
            left: 25px;
            bottom: 50px;
            padding: 4px 8px;
            font-size: 0.9em;
            min-width: 0;
            background-color: #fff;
            aspect-ratio: unset;

            & > div {
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: space-between;
            }
        }
    }
}
