.widget-box {
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(50% - 10px);
    background: var(--surface-light);
    color: var(--text-surface-light);
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(124, 218, 225, 0.3);

    .title {
        font-size: 16px;
        font-weight: 600;
    }

    &.primary {
        .widget-bar {
            box-shadow: 0 8px 20px 0 rgba(124, 218, 225, 0.3);
        }
    }

    &.secondary {
        .widget-bar {
            box-shadow: 0 8px 20px 0 rgba(241, 57, 57, 0.3);
        }
    }
}

.widget-bar {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    z-index: 2;
    text-transform: uppercase;
    box-shadow: 0 8px 20px 0 rgba(124, 218, 225, 0.3);
    background: var(--surface-light);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    align-items: center;

    svg.icon-base {
        fill: rgba(79, 78, 78, .5);
        transition: all 0.3s;
        margin-left: 4px;

        &.active {
            fill: #344054;
        }

        &:hover {
            fill: #6941C6;
        }
    }
}

.actions-bar {
    display: flex;
    align-items: center;
    justify-content: end;
    flex: 1 1 auto;
}