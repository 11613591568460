.table-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    .custom-table {
        .recycle-table-header {
            padding: 0;

            .table-header-wrapper {
                padding: 14px 12px;
                display: flex;
                flex: 1 1 auto;
                align-items: center;
                gap: 8px;

                &.click-able {
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        background: #bedafc;
                    }
                }

                .disabled {
                    cursor: not-allowed;
                }

                /* .click-able-icon {
                    &:hover {
                        background: #bedafc;
                    }
                } */

                svg.sort-icon {
                    margin: 0 4px;
                    transform: rotate(180deg);
                    fill: black;

                    &.ascending {
                        transform: rotate(0deg);
                    }
                }

                .sort-by-text {
                    font-weight: 400;
                    color: rgba(79, 78, 78, 0.5);

                    .bold-text {
                        color: #40464c;
                    }
                }
            }
        }

        .recycle-table-row {
            &.selected {
                .actions-cell-wrapper {
                    svg.icon-base {
                        fill: #b3c7da;
                    }
                }
            }

            .cell-wrapper {
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 100%;
                white-space: nowrap;

                .actions-cell-wrapper {
                    margin-left: auto;
                    padding: 2px 4px;
                    cursor: pointer;

                    &:active {
                        opacity: 0.8;
                    }

                    svg.icon-base {
                        fill: #cfd6dc;
                    }
                }

                .multi-fields-cell-wrapper {
                    display: flex;
                    align-items: center;
                    max-width: 100%;

                    .icon-cell {
                        display: inline-flex;
                        justify-content: center;
                        width: 50px;
                        height: 50px;
                        flex: 0 0 auto;
                    }

                    .multi-fields-cell-fields {
                        padding: 0 0 0 12px;
                        overflow: hidden;

                        div {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        div:first-child {
                            font-size: 16px;
                            font-weight: 600;
                        }

                        div:nth-child(2) {
                            color: #696c71;
                        }
                    }
                }

                .severity-cell-wrapper {
                    padding: 4px 8px;
                    color: #fff;
                    border-radius: 2px;
                }
            }
        }
    }

    .table-bottom-bar {
        text-align: center;

        .collapse-area {
            padding: 8px 0 0 0;
        }
    }
}
