.severity-kpis-container {
    display: inline-flex;
    align-items: center;
    /*flex: 1 0 50%;
	min-width: 50%;*/

    .severity-kpi-card-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 5px solid #4f4e4e;
        border-radius: 4px;
        margin-right: 24px;
        height: 140px;
        cursor: pointer;

        &.not-selected {
            opacity: 50%;
        }

        .card-left {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 8px;
            min-width: 90px;
        }

        .kpi-value-wrapper {
            display: flex;
            align-items: center;
            // justify-content: center;
        }

        .kpi-value {
            font-size: 60px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #4f4e4e;
        }

        .kpi-name {
            // font-family: Roboto;
            font-size: 16px;
            line-height: normal;
            color: rgba(79, 78, 78, 0.8);
        }

        .severity-count {
            width: 54px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 4px;
            color: #fff;

            &:first-child {
                margin-top: 0px;
            }

            &.level0 {
                background: #FFD561;
            }

            &.level1 {
                background: #f37224;
            }

            &.level2 {
                background: #e21818;
            }

            &.not-selected {
                opacity: 50%;
            }
        }
    }

    svg.icon-base.right {
        fill: var(--color-yellow, yellow);
        transform: rotate(90deg);
        margin-left: 8px;
    }

    svg.icon-base.up {
        fill: var(--color-red, red);
        transform: rotate(0deg);
    }

    svg.icon-base.down {
        fill: var(--color-green-light, green);
        transform: rotate(180deg);
    }

}