.custom-overlay {

}

.custom-tooltip {
    
    &.show {
        opacity: 1;
    }

    .tooltip-arrow {
        visibility: hidden;
    }

    .tooltip-inner {
        max-width: unset;
        background: inherit;
        color: inherit;
    }
    
}