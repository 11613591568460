/* The container */
.check-box-container { 
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    user-select: none; 
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    input {
      position: absolute;
      opacity: 0;
  
      &:checked ~ .checkmark {
        background-color: var(--checkbox-checked-bg, #112845);
        border-color: var(--checkbox-checked-bg, #112845);
        &:after {
            display: block;
        }
      }

      &[value='1'] ~ .checkmark {
        &:after {
            width: 80%;
            height: 65%;
            border-width: 0 0 3px 0;
            transform: rotate(0deg) translate(15%) scale(70%);
        }
      }
    }

    .checkmark {
      flex: 1 0 auto;
      height: 16px;
      width: 16px;
      background-color: var(--checkbox-bg, transparent);
      border: 1px solid var(--checkbox-border-colour, white);
      /* Create the checkmark/indicator (hidden when not checked) */
      &:after {
        content: "";
        display: none;
        width: 50%;
        height: 100%;
        border: solid #FFF;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg) translate(25%, -25%) scale(70%);
        -webkit-transform: rotate(45deg) translate(25%, -25%) scale(70%);
        -ms-transform: rotate(45deg) translate(25%, -25%) scale(70%);
      }
    }
  
    &:hover {
      .checkmark {
        background-color: var(--checkbox-hover-bg, white);
      }
    }
  }