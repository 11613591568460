.map {
    width: 100%;
    height: 100%;

    .map-spinner {
        position: absolute;
        z-index: 11;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.map::after {
    border-radius: 12px;
}
