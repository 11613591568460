.driver-info {
    display: inline-flex;
    align-items: center;
    gap: 1em;

    .driver-phone-number {
        color: #6941c6;
        font-weight: 600;
    }
}
