.login-form {
    padding: 40px;
    min-width: 340px;
    background-color: var(--surface);
    color: var(--text-surface, white);

    .buttons-wrapper {
        display: flex;
        justify-content: space-between;
        margin: 16px 0;

        >div:not(:last-child) {
            margin-right: 12px;
        }

        .button-container {
            flex: 1 1 auto;
        }
    }
}