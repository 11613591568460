.icons-page {
    padding: 24px;

    .icons-panel {
        display: flex;
        max-height: 100%;
    }

    .icons-setting {
        width: 200px;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        overflow: auto;
        border-left: 1px rgb(204, 204, 204) solid;
        padding-left: 8px;
    }

    .icons-wrapper {
        flex: 1 1 auto;
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
    }
    
    .icon-box {
        padding: 8px;
        margin: 4px;
        width: 10%;
        flex: 0 0 5%;
        min-width: 100px;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        transition: all 0.2s;
        &:hover {
            background: rgb(240, 240, 240);
            cursor: pointer;
        }
    }

    .icon-info {
        display: flex;
        overflow: hidden;
        max-width: 100%;
        margin-top: 4px;
    }

    .icon-index {
        border-radius: 4px;
        padding-right: 4px;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
    }
    
}



// --------
.colors-box {
  display: flex;
  flex-wrap: wrap;
}

.color-box {
    width: 24px;
    height: 24px;
    border: 1px transparent solid;

    &.selected {
        border: 1px gainsboro solid;
    }
}

// ----------
.checkbox-label {
    display: flex;
    align-items: center;
    padding: 4px;
    span:first-child {
        margin-right: 4px;
    }
}