.app-top-bar-container {
    // background: var(--surface, white);
    // box-shadow: 0px 1px 6px 1px rgba(125, 125, 125, 0.6);
    background: #fff;
    color: var(--surface, gray);
    box-shadow: 0 0 22.7px 0 rgba(79, 78, 78, 0.1);
    z-index: 10;
    display: flex;
    justify-content: space-between;
    padding-right: 1em;
    align-items: center;

    .button-label {
        padding: unset;
        gap: unset;

        .icon-base {
            margin-left: 4px;
        }
    }
}

.pf-icon-logo-wrapper {
    padding: 4px 12px;

    svg.icon-base {
        fill: var(--color-green-sea);
    }
}