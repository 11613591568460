.error-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.error-404-container {
    flex: 1 1 auto;
    height: 100%;
    display: flex;
    padding: 24px;
    align-items: center;
    justify-content: center;
    background: #2c2a29;
    color: #b5b2af;

    div:first-child {
        margin-right: 16px;
    }

    h1 {
        font-size: 120px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 16px;
    }
}