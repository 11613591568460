div.send-input {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 0 0;

    /* https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/ */
    div.grow-wrap {
        display: grid;
        flex: 1 1 auto;

        &::after {
            content: attr(data-replicated-value) " ";
            white-space: pre-wrap;
            visibility: hidden;
            grid-area: 1 / 1 / 2 / 2;
        }

        textarea {
            background: transparent;
            outline: unset;
            border: none;
            box-shadow: none;
            color: #4f4e4e;
            resize: none;
            overflow: hidden;
            padding: 0;
            grid-area: 1 / 1 / 2 / 2;
        }
    }

    .icon-base {
        height: 20px;
        min-width: 20px;
        width: 20px;
    }
}
