#context-popover.comments-popover {

    @media print {
        display: none;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-height: 40vh;
        overflow: auto;
    }

    .modal-footer {
        padding: 0;

        > div {
            flex: 1 1 auto;
        }
    }
}
