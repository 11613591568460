.basic-dropdown {
    display: flex;
    align-items: center;

    button {
        background-color: transparent;
        border: 1px solid transparent;
        transition: all 0.3s;
        color: inherit;

        .button-label {
            display: inline-flex;
            align-items: center;
            padding: 0 8px 0 0;
            border-right: 1px solid transparent;
            vertical-align: bottom;
            gap: 0.8em;
        }

        &:hover,
        &.show {
            transition: all 0.3s;
            border: 1px solid var(--primary-light, gray);
            background-color: var(--primary-light, gray);
        }
    }

    .dropdown-item {
        text-overflow: ellipsis;
        overflow: hidden;
        &:active,
        &.active {
            background-color: var(--primary, gray);
        }
    }
}


.simple-select {
    button {
        padding: 0 6px;
    }
}