.filter-popover {
	color: var(--text-surface, #4f4e4e);

	.top-section {
		display: flex;
		padding: 24px 0 0 0;

		.right-section {
			margin: 4px 16px 0 16px;
			display: flex;
			flex-direction: column;
			width: 300px;
			gap: 8px;

			.title {
				font-size: 16px;
				font-weight: 600;
			}
		}

		.selections {
			margin-top: 8px;
			height: 300px; // Just temp for development
			overflow: auto;
		}
	}

	.bottom-section {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
		box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.1);
		padding: 12px;
	}
}
