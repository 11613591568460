%info-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
}

.info-bar {
    @extend %info-group;
    padding: 0 0 8px 0;

    .info-group {
        @extend %info-group;
    }
}
