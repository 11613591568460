.load-spinner {
    display: inline-block;
    width: 30px;
    height: 30px;

    &.sm {
        width: 1rem;
        height: 1rem;
        margin: 0 6px;
    }
}

.load-spinner:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 6px;
    border-radius: 50%;
    border: 4px solid #000000;
    border-color: #95E0E6 transparent #95E0E6 transparent;
    animation: load-spinner 1.2s linear infinite;
}

.load-spinner.sm:after {
    width: 1rem;
    height: 1rem;
    margin: auto;
}

@keyframes load-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
