// Override colors
// .rdp {
//     --rdp-cell-size: 40px;
//     --rdp-accent-color: #0000ff;
//     --rdp-background-color: #e7edff;
//     /* Switch to dark colors for dark themes */
//     --rdp-accent-color-dark: #3003e1;
//     --rdp-background-color-dark: #180270;
//     /* Outline border for focused elements */
//     --rdp-outline: 2px solid var(--rdp-accent-color);
//     /* Outline border for focused and selected elements */
//     --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
// }

.rdp {
    --rdp-cell-size: 40px;
    --rdp-accent-color: rgba(124, 218, 225, 0.8);
    --rdp-outline: 2px solid var(--rdp-accent-color);
    --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}


// Date picker container
.pf-date-picker {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #1f1f1f;
    display: flex;
    flex-direction: column;
}

// Calendar days UI
.pf-date-picker {

    .rdp-row {
        border-color: transparent;
        border-bottom-width: 4px;
    }

    .rdp-day {
        border-radius: 0;
    }

    .rdp-day_range_end,
    .rdp-day_range_start {
        background: #18715e;
        color: #fff;
        box-shadow: 0 3px 2px 0 rgba(0, 71, 255, 0.2), 0 1.5px 1px 0 rgba(0, 31, 112, 0.25);
        border-radius: 6px;

        &:hover {
            background: #18715e;
        }
    }

    .rdp-day_selected {
        transition: all 150ms;

        &.rdp-day_range_middle {
            color: #18715e
        }
    }

}

// Dropdown UI
.pf-date-picker {
    margin: 10px 8px;
    .rdp-caption_dropdowns {
        --rdp-outline: 2px solid transparent;
        --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
        color: #141414;

        .rdp-dropdown_month,
        .rdp-dropdown_year {
            transition: all 0.3s;
            font-size: 20px;
            font-weight: 600;
            line-height: 1;

            .rdp-caption_label {
                border-radius: 2px;
            }

            select {
                font-size: 18px;
                font-weight: 500;
            }

        }
    }
}
