.modal-block.trips-kpis {
    border: none;
    border-radius: 0;
    padding: 0px 0px;
    --padding: 0px 0px;

    .modal-block-content {
        flex-direction: row;
    }
}

.kpi-v3 {
    flex: 1 1 auto; // dynamic width base on the content size
    // flex: 1 1 25%; // or fixed width with text ellipsis
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 12px 6px;
    border-radius: 12px;
    border: solid 2px #6941c6;
    color: #000;

    .title {
        color: inherit;
        font-family: inherit;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
    }

    .value {
        font-size: 24px;
        font-weight: 600;
        line-height: 1;
        text-align: center;
    }

    &.score {
        .value {
            color: #4bd291;
        }
    }
}