.recycle-table-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    color: #40464c;
    background: #fafafa;
    font-weight: 500;
    font-size: 14px;

    .recycle-table-headers-wrapper {
        display: flex;
        width: 100%;

        &.vertical-scroll {
            padding-right: 10px;
        }

        .recycle-table-header {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 1px solid #d5dbe1;
            background: #e6eded;

            &.click-able {
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                    background: #bedafc;
                }
            }
        }
    }

    .recycle-table-row {
        display: flex;
        width: 100%;
        background: #fbfbfb;
        border-bottom: 1px solid #d5dbe1;

        &:hover {
            background: #f3f5fb;
        }

        &.selected {
            background: #bedafc;
        }

        .recycle-table-cell {
            overflow: hidden;
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 12px 12px;
        }
    }
}
