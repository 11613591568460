.video-controller-container {

	.playing-bar {
		overflow: hidden;
		background: #565656;

		.progress-value {
			&.progress-buffer-value {
				background: #8b8b8b;
			}
		}

		input.progress-input {
			cursor: pointer;
		}
	}

	.player-controllers-wrapper {
		display: flex;
		align-items: center;
		padding: 20px 30px;
		background: var(--color-black2);

        &.minimised {
            padding: 2px 16px;

            .control-section {
                gap: 16px;

                .control-item .duration {
                    font-size: 14px;
                }
            }
        }

		.control-section {
			display: flex;
			flex: 1 1 auto;
			align-items: center;
            gap: 36px;

			.control-item {
				display: flex;
				align-items: center;

				.duration {
					font-size: 21px;
					font-weight: 600;
					color: #b4b4b4;
				}

				svg.button {
					fill: #fff;
			
					&.disable {
						// fill: #6D6D6D;
                        opacity: 0.5;
					}
				}
			}

			.volume-wrapper {
				display: flex;
				align-items: center;

				.control-item {
					margin-right: 4px;
				}

				.volume-bar {
					width: 110px;

					input.progress-input {
						cursor: pointer;
					}

					.progress-bar-container {
						height: 2px;
						background: #8b8b8b;
					}

				}
			}
		
			&:last-child {
				justify-content: flex-end;
			}
		}
	}
}
