.kpis-container {
	display: flex;
	// flex-wrap: wrap;
	justify-content: space-between;
}

.pf-card.kpi-card {
	flex: 0 1 25%;
	margin: 0 16px 0 0;
	background: var(--surface-light);
	box-shadow: none;
	border: solid 1px rgba(0, 100, 78, 0.2);
	display: flex;
	align-items: center;
	padding: 20px 20px;
	font-size: 16px;

	&:last-child {
		margin: 0 0 0 0;
	}

	.kpi-icon {
		background-color: rgba(72, 95, 223, 0.2);
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 auto;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		margin-right: 8px;
	}

	.kpi-card-right {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		flex: 1 0 auto;
	}

	.kpi-value-wrapper {
		display: flex;
		align-items: center;
		font-size: 42px;
		font-weight: 600;
		color: var(--text-def);

		.kpi-value {
			line-height: normal;
		}
	}

	.text-variant:last-child {
		color: var(--text-surface);
		font-weight: 500;
	}

	svg.icon-base.right {
		fill: var(--color-yellow, yellow);
		transform: rotate(90deg);
		margin-left: 8px;
	}

	svg.icon-base.up {
		fill: var(--color-red, red);
		transform: rotate(0deg);
	}

	svg.icon-base.down {
		fill: var(--color-green-light, green);
		transform: rotate(180deg);
	}
}