.incident-management-screen {
    background: var(--surface);
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
    flex-direction: column;
    display: flex;

    section {
        display: flex;
        flex: 1 1 auto;
        overflow: hidden;

        padding: 16px;

        .section-left,
        .section-right {
            display: flex;
            flex-direction: column;
        }

        .section-left {
            flex: 1 1 auto;
            display: grid;
            grid: "kpis-container" auto "incidents-view-container" 1fr;
            width: 70%;
            padding-right: 8px;
        }

        .section-right {
            flex: 1 1 auto;
            min-width: 400px;
            max-width: 580px;
            width: 30%;
            padding-left: 8px;

            // .header-wrapper {
            //     flex: 0 0 calc(50% - 10px);
            // }
            .header-wrapper {
                &:first-child {
                    flex: 0 1 40%;
                    min-height: 280px;
                    max-height: 350px;
                }

                flex: 1 1 auto;
            }
        }
    }

    .spacer {
        width: 20px;
        flex: 0 1 auto;

        &.v {
            height: 20px;
            width: unset;
        }
    }


}

.incidents-view-container {
    display: flex;
    flex: 1 1 auto;
    padding: 20px 0 0 0;
    overflow: hidden;

    .widget-box {
        flex: 0 0 calc(50% - 10px);
        overflow: hidden;

        &:first-child {
            margin-right: 20px;
        }
    }

}

svg.sort-icon.icon-base {
    transform: rotate(180deg);

    &.ascending {
        transform: rotate(0deg);
    }
}

.widget-bar {
    svg.icon-base.create-event-btn {
        margin-right: auto;
    }
}