.chart-incidents-types {
    display: flex;
    flex: 0 1 50%;
    min-height: 140px;
    min-width: 500px;

    .recharts-tooltip-wrapper {
        &:focus-visible {
            outline: 0;
        }
    }

    .recharts-layer {
        .cursor {
            cursor: pointer
        }
    }
}
