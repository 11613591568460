.installer-page {
    display:flex;
    height: 100%;

    .wo-right-pane {
        flex: 65%;
        background-color: var(--surface, #f2f0ed);
        padding: 10px 30px;
        box-shadow: inset 2px -6px 6px rgba($color: #000000, $alpha: 0.1);
        overflow: scroll;
    }

    ::-webkit-scrollbar {
        width: 15px;
    }

    ::-webkit-scrollbar-thumb {
        background: #b5b2af;
        border: 5px solid transparent;
        border-radius: 18px;
        background-clip: content-box;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background: #9FA7A3;
        border: 5px solid transparent;
        background-clip: content-box;
    }
}
