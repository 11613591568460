.driver-score-row {
    display: flex;
    justify-content: center;
    width: 100%;

    .driver-score-block {
        height: 48px;
        display: flex;
        align-items: center;
        gap: 15px;
        color: #344054;

        .score-label {
            font-size: 11px;
            line-height: 2.1;
            letter-spacing: -0.22px;
            text-align: center;
            margin-left: 15px;
            &.reoccurance {
                width: 75px;
            }
            &.safety {
                width: 65px;
            }
        }

        .score-value {
            font-size: 52px;
        }
    }
}