.has-tooltip {
    cursor: pointer;
    &::after {
        content: ' *';
    }
}

#popover-basic {
    &.overlay-container {
        font-size: inherit;
        padding: 8px 16px;
        border-radius: 12px;
        border: solid 1px rgba(79, 78, 78, 0.8);

        .overlay-title {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 14px;
            font-weight: 500;
            color: #344054;

            .info-icon {
                fill:#000;
                height: 24px;
                width: 24px;
            }
        }

        .popover-arrow {
            display: none;
        }

        .info-row-value {
            a {
                text-decoration: underline;  
                color: #6941c6;
                cursor: pointer;
            }
        }
    }
}
