.card-item {
    padding: 8px;
    display: flex;

    .column {
        display: flex;
        flex-direction: column;
    }
}

.card-field {
    // color: red;
}

.card-title {
    font-size: 18px;
    font-weight: 600;
}

.title-m {
    font-weight: 600;
    font-size: 22px;
}

.modal-dialog {
    color: var(--gray);

    .modal-content {
        background-color: var(--surface, transparent);
    }

    .btn-close {
        color: var(--icon, gray);
    }
}

// Tabs
.nav-tabs>li.active>button,
.nav-tabs>li.active>button:focus,
.nav-tabs>li.active>button:hover {
    background-color: transparent;
    color: var(--primary-dark);
    // color: #2c2a29;
    cursor: default;
    font-weight: 600;
    font-variation-settings: "wght" 600;
}

.nav-tabs {
    .nav-item {
        background-color: transparent;
    }

    .nav-link {
        background-color: transparent;
        color: var(--primary);
        font-weight: 600;
        font-variation-settings: "wght" 600;

        &:hover {
            cursor: pointer;
        }

        &.active {
            background-color: transparent;
            color: var(--secondary);
        }
    }
}

// ---

input {
    font-weight: 400;
    letter-spacing: .25px;
    border: 1px solid var(--primary, #625f5c);
    border-radius: 6px;
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 40%);
    color: var(--text-def, #2c2a29);
    line-height: 1.5em;
}

label {
    color: var(--primary, #625f5c);
    font-size: 16px;
    font-weight: 600;
    padding: 4px 0;
}

/* Scroll bar */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    // background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #7e7e7e;
    border-radius: 18px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// React bootstrap
.popover-body {
    padding: 0;
}

.popover,
.modal-dialog {
    max-width: unset;
    width: unset;
}

// -----------------

// links
a {
    color: unset;
    text-decoration: none;

    &:hover {
        color: unset;
    }
}

.dimmed {
    position: relative;
}

.dimmed:after {
    content: " ";
    z-index: 10;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}

.screen-shot {
    opacity: 0.3;
}

.brush-chart {
    rect:first-child {
        rx: 5;
        ry: 5;
    }

    rect.recharts-brush-slide {
        fill-opacity: 1;
        fill: #7cdae1;
        rx: 5;
        ry: 5;
    }
}

.custom-bar-chart-tooltip {
    background: white;
    padding: 12px;
    border: 1px rgb(189, 189, 189) solid;
    line-height: normal;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    max-width: 400px;
    // font-size: 14px;

    span:last-of-type {
        color: #4f4e4e;
    }
}

.highlight-text {
    background: #7cdae1;
}