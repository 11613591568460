div.impact-image {
    display: flex;
    align-items: center;
    height: 110px;

    img {
        position: relative;
        right: 25px;
        transform: rotate(270deg);
        height: 180px;
    }
}