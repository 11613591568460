.video-player-container {
	display: flex;
	flex-direction: column;
	position: relative;
	
	.twin-screens {
        .screen {
            opacity: 1;
            transition: opacity 200ms ease-out;
        }
		& > *:first-child > * {
			border-top-left-radius: 8px;
		}
		& > *:nth-child(2) > * {
			border-top-right-radius: 8px;
		}
	}

	.player-controllers-wrapper {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
}
