

.custom-date-picker {
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 0 20px 0 rgba(79, 78, 78, 0.2);

    .custom-date-picker-wrapper {
        display: flex;
    }
}

.custom-date-picker {

    .date-picker-side-panel {
        text-align: start;
        font-size: 14px;
        color: #171717;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 0.14px;
        padding: 24px 24px 24px 24px;
        border-right: #e7edff 1px solid;
    
        .panel-item {
            padding: 8px;
            border-radius: 4px;
            transition: all 0.3s;
            
            &:hover:not(:disabled, .disabled) {
                cursor: pointer;
                background: #e7edff;
            }
            
            &.selected {
                background: rgba(124, 218, 225, 0.8);
                color: #fff;
            }
        }
    }
}