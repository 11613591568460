.button-container {
    display: inline-flex;
    justify-content: center;
    border-radius: 20px;
    padding: 2px 12px;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    background-color: transparent;
    cursor: pointer;
    border: 1px solid var(--secondary);
    color: var(--secondary);
    transition: all 0.3s;

    &.uppercase {
        text-transform: uppercase;
    }

    &:disabled,
    &.disabled {
        cursor: default;
        // background-color: var(--button-disabled);
        // color: #6f7581;
        opacity: 0.5;
    }

    span {
        flex: 1 1 auto;
        text-align: center;
    }
}

.button-container {

    &.fill {
        background-color: var(--secondary);
        color: var(--text-secondary);
    }

    &.primary {
        border: 1px solid var(--primary);
        color: var(--primary);

        &.fill {
            background-color: var(--primary);
            color: var(--text-primary);
        }
    }

    &.primary-light {
        border: 1px solid var(--primary-light);
        color: var(--primary-light);
        &.fill {
            background-color: var(--primary-light);
            color: var(--text-primary-light);
        }
    }
}

.button-container:not(:disabled, .disabled) {

    &:hover {
        color: var(--text-secondary);
    }

    &:active {
        opacity: 0.8;
    }

    &.primary {
        &:hover {
            background-color: var(--primary);
            color: var(--text-primary);
        }

        &:active {
            opacity: 0.8;
        }
    }

    &.primary-light {
        &:hover {
            background-color: var(--primary-light);
            color: var(--text-primary-light);
        }

        &:active {
            opacity: 0.8;
        }
    }
}

.button-container.gradient {
    &.primary-light {
        &:hover:not(:disabled, .disabled) {
            background: linear-gradient(107deg, #167063 12%, #54b1ad 86%);
            -webkit-text-fill-color: unset;
            background-clip: initial;
        }
    }
}