.landing-screen-container {
	height: 100%;
	display: flex;
	flex-direction: column;

	.screen-content {
		padding: 24px;
		display: flex;
		flex: 1 1 auto;
		align-items: center;
		justify-content: center;
	}

	.link-icon {
		text-align: center;
		margin: 12px;
		padding: 24px;
		color: var(--primary);
		border-radius: 4px;
		border: 1px solid var(--primary);
		font-size: 24px;
		font-weight: 600;
		transition: all 0.3s;

		svg {
			fill: var(--primary);
		}

		&:hover {
			background: var(--primary);
			color: var(--text-primary);

			svg {
				fill: var(--text-primary);
			}
		}
	}
}