.base-dialog {
	.modal-dialog {
		width: 30%;
	}
	.modal-content {
		box-shadow: 3.9px 3.9px 2.6px 0 rgb(0 0 0 / 25%);

		.modal-header {
			padding: 8px 20px;
			align-items: center;

			.btn-close {
				background-size: 13px;
				padding: 14px;
			}
			.modal-title {
				font-size: 18px;
				padding-left: 6px;
			}
		}
		
		.modal-body {
			text-align: center;
			min-height: 90px;
			white-space: pre-line;
		}

		.modal-footer {
			align-self: stretch;
			flex-grow: 1;
			display: flex;
			gap: 50px;
			flex-direction: row;
			justify-content: center;
			padding: 16px;

			.button-container {
				min-width: 100px;
				height: 35px;

				&.close-button {
					border-radius: 8px;
					box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
					border: solid 1px #d0d5dd;
					background-color: #fff;
					color: #344054;
					font-size: 16px;
				}
			}
		}
	}
}