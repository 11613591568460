.selectableOption {
	padding: var(--padding-card, 8px) 0;

    label {
        font-size: 1em;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: var(--padding-card, 8px);
    }

    &:hover {
        background-color: var(--card-hover-bg);
    }
}

.option-header {
    display: flex;
}

.collapse-icon {
    min-width: 26px;
}

.optionLevel1 {
    border-radius: 4px;
	border: 1px solid var(--border-colour);
    margin-bottom: 0.5em;
}

.optionLevel1 > * {
    padding-left: 8px;
}

.optionLevel2 {
    padding-left: 52px;

    label {
        font-size: 0.9em;
        font-weight: normal;
    }
}

.optionIcon {
    display: inherit;
}