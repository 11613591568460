.modal-state-menu {
    display: inline-flex;
    align-items: center;

    .basic-menu {
        button {
            &:hover,
            &.show {
                border: solid 1px transparent;
                background-color: rgba(217, 217, 217, 0.3);
                color: inherit;
            }
        }
    }
}