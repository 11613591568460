.selection-container {
    // background-color: aqua;
    display: flex;

    &.direction-column {
        flex-direction: column;
    }

    select {
        // width: 100%;
        padding: 8px 8px;
        font-size: 14px;
    }

    span {
        padding: 4px 0;
    }
}