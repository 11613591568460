.block-title {
    display: flex;
    align-items: center;

    svg.icon-base {
        margin: 0 8px 0 0;
    }
}

.title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: #344054;
}