div.filter-chip {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    color: var(--text-surface, #4f4e4e);
    background-color: var(--card-hover-bg, white);
    border: 1px solid var(--card-hover-bg, #d4d4d4);
    border-radius: 13px;
    padding: 0 10px;
    margin: 0 5px;
    font-size: 11px;
    font-weight: 400;

    &.action {
        cursor: pointer;

        &:hover {
            border: 1px solid var(--color-turquoise-light, #7cdae1);
        }
    }

    &.clear-all {
        border: 1px solid var(--text-surface, #4f4e4e);
    }

    &:active {
        color: var(--color-turquoise-light, #7cdae1);
        border: 1px solid var(--color-turquoise-light, #7cdae1);
        span.icon-remove {
            background-color: var(--color-turquoise-light, #7cdae1);
        }
    }

    span {
        max-width: 120px;
    }

   span.icon-remove {
        height: 13px;
        width: 13px;
        cursor: pointer;
        mask-image: url("/assets/svg/cancel_13.svg");
        background-color: var(--text-surface, #4f4e4e);

        &:hover {
            background-color: var(--color-turquoise-light, #7cdae1);
        }
    }

    &.disabled {
        background-color: lightgray;
        color: gray;

        &.active {
            border: 1px solid gray;
        }

        &:hover {
            border: 1px solid darkgray;
        }
    }
}

div.other-filters-container {
    position: absolute;
    display: none;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
    padding: 4px 0;
    border: 1px solid var(--border-colour);
    border-radius: 6px;    
    box-shadow: 0px 2px 6px 0px lightgrey;
    background-color: white;
    z-index: 1;

    &.show {
        display: flex;
        animation: 400ms drop-down;
        max-height: 100px;
        overflow: auto;

        @keyframes drop-down {
            from {
                max-height: 0;
            }
            to {
                max-height: 100px;
            }
        }
    }

    span {
        max-width: 250px;
    }
}
