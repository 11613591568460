.action-icon {
	cursor: pointer;
}

.resource-icon {
	width: 50px;
	height: 50px;
}

.incident-icon-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 70px;
	height: 70px;
	box-shadow: 4px 0 8px 0 rgba(124, 218, 225, 0.2);
	background-color: var(--surface-light);
	border-radius: 4px;

	svg.severity-icon {
		position: absolute;
		top: 16px;
		right: -8px;
	}
}
