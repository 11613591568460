.wo-container {
    border-bottom: 1px solid var(--primary-dark);
    padding: 3px 10px;
    display: flex;
    gap: 5px;
    background-color: #f9f9f8;

    &.selected {
        background-color: #fff;
        font-weight: 500;
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.25);
        z-index: 10;
    }

    .wo-col {
        flex: 30%;
        overflow: hidden;
        &.small {
            flex: 15%;
        }
        &.large {
            flex: 45%;
        }
        div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            span {
                font-size: 0.7rem;
            }
        }
    }
}