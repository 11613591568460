.map-location-picker {
    border-radius: 12px;
    font-size: 16px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(79, 78, 78, 0.2);

    .map-container {
        width: 400px;
        height: 400px;
    }
}