.app-layout-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .app-main-view {
        display: flex;
        flex: 1 1 auto;
        max-height: 100%;
        overflow: auto;
    }
}