.incident-list-screen {
    height: 100%;
    display: flex;
    flex-direction: column;

    section {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding: 16px;

        .section-top {
            display: flex;
            justify-content: space-between;
        }

        .section-bottom {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
        }
    }

    .collapse-bar {
        margin-bottom: 8px;
    }
}
