.twin-screens {
    display: flex;
    width: 100%;

	.screen {
		display: flex;
		flex-basis: 50%;

		& > * {
			max-width: 100%;
			min-width: 100%;
			box-shadow: inset 0 1px 3px rgb(0 0 0 / 40%);
		}
	}
}