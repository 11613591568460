.resource-row {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .resource-wrapper {
        display: inline-flex;
        gap: 5px;

        .resource-label {
            color: rgba(79, 78, 78, 0.8);
            font-weight: normal;
        }
        .resource-value {
            color: #4f4e4e;
            max-width: 80px;
        }
    }
}