.split-button-container {
    background: #fff;
    display: flex;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid var(--border-colour);
    font-size: 14px;

    &.error {
        border: 1px solid salmon;
    }
    &.dropdown-toggle {
        &::after {
            display: none;
        }
    }

    input {
        background: transparent;
        outline: 0;
        border: 0;
        box-shadow: none;
        padding: 2px 4px;
        flex: 1 1 auto;
        width: 100%;
    }

    .split-button-icon {
        height: 100%;
        border-left: 1px solid var(--border-colour);
        align-items: center;
        display: flex;
        justify-content: center;
        flex: 0 0 25px;
        min-height: 25px;

        &:hover {
            cursor: pointer;
        }
    }
}