.modal-block {
    --padding: 10px 16px;

    display: flex;
    flex-grow: 1;
    gap: 12px;
    border-radius: 12px;
    border: solid 1px rgba(79, 78, 78, 0.8);
    font-size: 16px;
    line-height: 1.5;

    &.full-size {
        flex-direction: column;
        flex-grow: 1;
        gap: 0;
        justify-content: space-between;

        .map-container {
            border-radius: 12px;
            width: 506px;
        }
    }

    &.compact {
        font-size: inherit;
        line-height: 1;
    }

    &.with-icon {
        padding: var(--padding);

        svg {
            width: 32px;
            height: 32px;;
        }

        .modal-block-content {
            align-self: center;
            font-size: 14px;
            line-height: 1.43;
            color: #344054;
            margin: 0;
        }
    }

    .modal-block-content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        gap: 9px;
        margin: var(--padding);
        overflow: hidden;
    }
}
