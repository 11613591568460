.header-wrapper {
    display: flex;
    flex-direction: column;
    border: solid 1px rgba(0, 100, 78, 0.2);
    border-radius: 6px;
    background: #fff;

    .caption {
        font-size: 0.9em;
    }

    .header {
        font-weight: 600;
    }

    .wrapper-header {
        display: flex;
        justify-content: space-between;
        height: 3em;
        padding: 0.8em;
        border-bottom: solid 1px rgba(0, 100, 78, 0.2);
    }

    .wrapper-body {
        flex: 1 1 auto;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        overflow: hidden;
    }
}