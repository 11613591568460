.collision-images {
    display: flex;
    gap: 4px;

    .collision-image {
        height: 51px;
        width: 75px;
        border: solid 2px rgba(79, 78, 78, 0.4);
        border-radius: 10px;
        cursor: pointer;

        &.view-all {
            display: flex;
            flex-direction: column;
            font-size: 10px;
            line-height: 1.5;
            font-weight: normal;
            color: rgba(79, 78, 78, 0.8);
            text-align: center;
            justify-content: center;
        }

        img {
            height: 48px;
            width: 72px;
            border-radius: 8px;
        }
    }
}
