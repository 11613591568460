#context-popover.sms-popover {
    
    .modal-body {
        padding: 0;
    }

    .modal-footer {
        margin: 0.5rem 0 0;
        padding: 0.5rem 0 0;

        .feedback {
            display: flex;
            flex-grow: 1;
            justify-content: center;

            &.error {
                color: var(--error);
            }
            &.info {
                color: var(--info);
            }
        }
    }
}
