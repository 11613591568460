.modal-info-row {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    font-weight: 600;

    .info-row-label {
        text-align: left;
        color: #101828;
        white-space: nowrap;

        &.highlight {
            text-decoration: underline;
        }

        .label-non-bold {
            font-weight: normal;
        }        
    }

    .info-row-value {  
        text-align: right;                
        font-weight: normal;
        color: rgba(79, 78, 78, 0.8);
        overflow: hidden;
        text-overflow: ellipsis;

        .iconWrapper {
            display: flex;
            gap: 10px;
        }
    }

    &.flow {
        display: inline-block;
        text-align: justify;

        .info-row-value {
            padding-left: 15px;
            font-size: 13px;
        }
    }
}
