.selection-info {
	display: flex;
    justify-content: space-between;
    gap: 1em;

    .greedy {
        flex-basis: 100%;
    }

    .select-link {
        display: flex;
        flex: 1 0 auto;
        justify-content: flex-end;
        color: rgba(72, 95, 223, 0.75);
        cursor: pointer;
    }
}