.wo-list-tab {
    flex: 35%;
    background-color: white;

    .wo-tab-title {
        padding: 10px 10px;
        font-size: 18px;
        line-height: 26px;
        font-weight: bold;
    }

    .list-container {
        height: calc(100% - 80px - 46px);
        padding-bottom: 5px;

        .wo-list {
            .group-title {
                background-color: var(--primary-dark);
                color: rgb(241, 230, 230);
                padding: 5px;
                padding-left: 10px;
                font-weight: 500;
            }
            overflow-y: scroll;
        }
        .empty-state {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            div {
                text-align: center;
            }
         }
    }
}
