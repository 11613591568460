.wo-form {
    margin: 0px 20px;

    h4 {
        text-align: left;
        margin: 20px 0 50px 0;
    }

    .form-row {
        display: flex;
        column-gap: 25px;
        .text-input-container, .form-block {
            flex: 50%;
            margin-bottom: 10px;

            label {
                display: block;
            }
        }
        .text-input-container input, .split-button-container {
                border-color: hsl(0, 0%, 80%);
                height: 38px;
                box-shadow: none;
                padding-left: 5px;
        }
    }

    .installer-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .err-container {
            color: red;
            flex: 50%;
        }
        .button-v1 {
            padding: 10px 20px;
            font-size: 1em;
            width: 200px;
            height: 50px;
            flex: 10%;

            &:hover:not(:disabled, .disabled) {
                background: var(--primary-dark);
                border-color: #fff;
            }
        }
    }
}