#context-popover {
    width: 18vw;
    border: none;
    box-shadow: 5px 5px 10px 5px rgb(0 0 0 / 25%);

    .popover-arrow {
        display: none;
    }

    .dialog {
        position: relative;

        .modal-dialog {
            margin: 0;

            .modal-content {
                padding: 1em;

                .modal-header {
                    display: flex;
                    padding: 0;
                    height: 4rem;

                    .btn-close {
                        position: absolute;
                        top: 1rem;
                        right: 1rem;
                        width: 0.7rem;
                        height: 0.7rem;
                        background-size: cover;
                        padding: 0;
                    }

                    .icon-base {
                        width: 4rem;
                        height: 2rem;
                    }
                    
                    .title {
                        font-size: 1.1rem;
                        font-weight: 600;
                    }
                }

                .modal-footer.hidden {
                    display: none;
                }
            }
        }
    }
}
