// Recycle list
.list-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}


.recycle-list-parent {
    overflow-x: hidden;
    flex: 1 1 auto;
}

.recycle-list {

    >div,
    .list-row {
        position: absolute;
    }
}