.modal-collision-graph {
    min-height: 130px;
    margin-right: 40px;
    margin-left: -40px;
    font-size: 12px;
}

.modal-collision-graph-unavailable {
    min-height: 130px;
    width:100%;
    position: relative;
}
