
.custom-date-picker.date-time-picker {

    .custom-date-picker-wrapper {
        max-height: 380px;
    }
    
    .date-picker-side-panel {
        flex: 1 1 auto;
        display: flex;
        max-height: 345px;
        padding: 22px 12px 12px 12px;
        border-left: #e7edff 1px solid;
        border-right: unset;
    }
}


.time-panel {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
    .panel-title {
        font-size: 18;
        font-weight: bold;
        padding: 0 0 6px 0;
    }
}

.time-list-picker {
    overflow-y: scroll;
}

.time-item,
.date-time-overlay-trigger {
    white-space: nowrap;
}

.panel-item.time-item {
    &.disabled {
        opacity: 0.5;
    }
}