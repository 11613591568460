pre {
    outline: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
}

.string {
    color: #e74c3c;
}

.number {
    color: #2ecc71;
}

.boolean {
    color: #1714c7;
}

.null {
    color: #ec04b2;
}

.key {
    color: #3498db;
}