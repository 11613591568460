.filter-tabs-container {
	color: var(--text-surface, #4f4e4e);
	font-size: 12px;
	padding-right: 16px;
	border-right: 1px solid var(--border-colour);

	.tab {
		display: flex;
    	justify-content: space-between;
		min-width: 120px;
		padding: 8px 8px 8px 24px;
		cursor: pointer;

		&.selected {
			background: var(--color-turquoise-light, #7cdae1);
			border-bottom-right-radius: 6px;
			border-top-right-radius: 6px;
			font-weight: 500;
		}

		span.selectionMarker {
			width: 8px;
			height: 8px;
			margin-top: 5px;
			border-radius: 5px;
			border: 1px solid #167063;

			&.applied {
				border: none;
				background-image: linear-gradient(142deg, #167063 6%, #54b1ad 90%);
			}
		}
	}
}