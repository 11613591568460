

.dropdown-input-container {
    position: unset;
    font-size: inherit;
    font-size: 14px;

    input {
        &::placeholder {
            color: #2c2a29;
        }
        &:focus {
            &::placeholder {
                color: #adb5bd;
            }
        }
    }
    
    .split-button-container {
        width: 100%;
        height: 100%;
        &.grey {
            input {
                color: #adb5bd;
            }
        }
    }

}

.dropdown-input-menu-container {
    background: #fff;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: inherit;
    max-width: 200px;
}