.modal-block.collision {
    --pad: 4px;

    padding: var(--pad);
    gap: 4px;
    width: 515px;

    div {

        .widget-mask {
            position: absolute;
            display: flex;
            color: #FFF;
            background-color: #000;
            border-radius: 8px;
            opacity: 0.6;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;

            p {
                margin: auto;
                font-size: 24px;
                opacity: 0.8;
                text-align: center;
            }
        }
    }

    .video-graph-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}
