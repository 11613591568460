.app-side-navs.accordion {

    .accordion-header,
    .accordion-item {
        padding: 0;
        background: transparent;
        border: 0;
        border-radius: 0;
    }

    .accordion-item {
        display: flex;
        flex-direction: column;
        transition: all 0.3s;
        background: transparent;
        width: 67px;

        .side-nav-link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding: 6px 8px 6px 8px;
            width: 66px;
            background: transparent;
        }

        &.has-sub-links:not(.expanded) {

            .accordion-header {
                .side-nav-link {
                    padding: 6px 0px 6px 8px;
                }
            }
        }

        svg.icon-base {
            fill: #f8fbfb;

            &.nav-expand-icon {
                stroke: #f8fbfb;
                margin: 0 4px;
            }

            &.stroke-color {
                stroke: #f8fbfb;
                fill: none;
            }
        }

        a {

            &:hover {
                opacity: 0.8;
            }

            &.active {

                svg.icon-base {
                    fill: #18715e;

                    &.stroke-color {
                        stroke: #18715e;
                        fill: none;
                    }
                }

                .side-nav-link {
                    box-shadow: 0 0 20px 0 rgba(79, 78, 78, 0.5);
                    background: #f0f3f3;
                    width: 54px;
                }
            }
        }

        &.expanded {
            background: #114f41;
            transition: all 0.3s;

            &:not(.has-sub-links) {
                background: transparent;
            }

            button.accordion-button {
                &::after {
                    display: none;
                }
            }

        }

    }

    .accordion-body {
        background: transparent;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    button.accordion-button {
        background: transparent;
        padding: 0;
        outline: 0;
        border: 0;
        box-shadow: none;

        &:not(.collapsed) {
            background: transparent;
            box-shadow: none;
        }

        &:active,
        &:focus {
            outline: 0;
            border: 0;
            outline-color: transparent;
        }

        &::after {
            display: none;
        }
    }

}

.tooltip-target.nav-tooltip {
    background: transparent;
    padding: 4px 0px;
    width: 100%;
}
