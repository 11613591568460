.progress-bar-container {
	display: flex;
	position: relative;
	background: #565656;
	height: 16px;
	width: 100%;

	&.minimised {
		height: 8px;
	}

	.progress-wrapper {
		display: flex;
		flex: 1 1 100%;
		position: relative;
	}

	.progress-value {
		position: absolute;
		height: 100%;
		background: #167063;
		max-width: 100%;
	}

	.progress-input {
		appearance: none;
		background: transparent;
		outline: none;
		border: 0;
		width: 100%;
		position: absolute;
		opacity: 0;
		height: 100%;
	}
}