.hover-tooltip {
    padding: 0 0 0 0;
    position: absolute;
    border-radius: 6px;
}

.tooltip-window {
    color: #4f4e4e;
    padding: 4px 0;

    .tooltip-title {
        font-weight: 600;
        padding: 4px 8px;
        display: inline-block;
        white-space: nowrap;
    }

    .tooltip-row {
        display: flex;
        padding: 2px 8px;

        a {
            &:hover {
                background: #cacaca;
            }
        }

    }
}

.tooltip-target {}