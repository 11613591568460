.modal-block.weather {
    --pad: 4px;
    --radius: 8px;
    flex-grow: 0;
    align-self: center;

    .modal-block-content {
        flex-direction: row;
        gap: var(--pad);
        margin: 0;

        .weather-condition-cell {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: solid 1px rgba(79, 78, 78, 0.8);
            border-radius: var(--radius) 0 0 var(--radius);
            margin: var(--pad) 0 var(--pad) var(--pad);
            padding: var(--pad);
            font-weight: normal;

            .weather-condition-label {
                white-space: nowrap;
            }
        }

        .weather-status-indicators {
            display: inline-flex;
            flex-direction: column;
            justify-content: space-between;
            gap: var(--pad);
            margin: var(--pad) var(--pad) var(--pad) 0;
            font-weight: normal;
            min-width: 150px;

            .weather-status-indicator {
                border: solid 1px rgba(79, 78, 78, 0.8);
                padding: 0 var(--pad) 0 16px;
                font-size: 12px;

                &.top {
                    border-top-right-radius: var(--radius);
                }
                &.bottom {
                    border-bottom-right-radius: var(--radius);
                }
                &.Mid .progress-value {
                    background-color: #FFD561;
                }
                &.High .progress-value {
                    background-color: #e21818;
                }
            }
        }
    }
}
