.wo-actions-bar {
    background-color: #e6eded;
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    .wo-search {
        width: 280px;
    }

    .button-v1 {
        float:right;
        padding: 5px 10px;
        font-size: 1em;
        &:hover {
            background: var(--primary-dark);
            border-color: #fff;
        }
    }
}