.bread-crumbs-container {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: normal;
    flex: 1 0 auto;

    a.bread-crumb {
        text-decoration: none;
        color: rgba(79, 78, 78, 0.8);
        font-size: 16px;

        &.active {
            font-weight: 600;
            font-size: 18px;
            color: #4f4e4e;
        }

        &:hover {
            color: rgba(72, 95, 223, 0.8);
        }

        &:active {
            color: var(--primary);
        }
    }
}
