// *****************************************************************************
// COLORS
// *****************************************************************************
// https://flatuicolors.com/palette/defo
// ------------------------------------------------------------------

// ------------------------------------------------------------------
//  GLOBAL CSS VARS AND COLORS DEFINE IN theme.js
// ------------------------------------------------------------------


:root {
	height: 100%;
	width: 100%;
	display: flex;
	box-sizing: border-box;
	// Colors
	--primary: #1abc9c;
	--primary-dark: #e67e22;
	--primary-light: #16a085;
	--secondary: #344955;
	--secondary-dark: #0e263f;
	--secondary-light: #16a085;
	--surface: #f2f0ed;
	--surface-dark: #94908d;
	--surface-light: #e0ddd9;
	--text-primary: #ffff;
	--text-primary-dark: #ffff;
	--text-primary-light: #ffff;
	--text-secondary: #fff;
	--text-secondary-dark: #fff;
	--text-secondary-light: #000;
	--text-surface: #000;
	--text-surface-dark: #000;
	--text-surface-light: #000;
	--icon: #BA274A;
	--text-def: #ecf0f1;
	--success: #2c3e50;
	--error: #d35400;
	--danger: #f2f0ed;
	--warning: #b5b2af;
	--info: #4A6572;
	--link: #c81023;
	--link-active: #b94a48;
	--button: #27ae60;
	--button-disabled: #4A6572;
	--button-active: #BA274A;
	--link-icon: #BA274A;
	--link-icon-bg: #34495e;
	--border-colour: #e6eded;
	--card-selected-bg: #7cdae1;
	--card-hover-bg: rgba(124, 218, 225, 0.16);
	--checkbox-border-colour: black;
	--checkbox-hover-bg: white;
	--checkbox-checked-bg: black;
	// Layouts
	--grid-gap: 16px;
	--padding-bar: 10px 16px;
	--padding-section: 16px;
	--padding-card: 8px;
}

body {
	flex: 1 1 auto;
	display: flex;
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	width: 100%;
	height: 100%;

	&.hideForPrint {
		@media print {
			display: none;
		}
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}