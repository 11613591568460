.comment-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;

    .comment-header {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
    }

    .label {
        font-size: 1rem;
        font-weight: 700;
    }

    .system {
        display: flex;
        align-items: center;
        gap: 0.2rem;
        color: var(--color-green-sea);

        .icon-base {
            fill: var(--color-green-sea);
            width: 1.4rem;
            height: 1.4rem;
        }
    }

    .comment-time {
        color: rgba(79, 78, 78, 0.8);
        min-width: fit-content;
    }
}