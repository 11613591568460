.modal-driver-info {
    display: flex;
    gap: 12px;

    .modal-info-row {
        flex-grow: 1;
        align-items: center;
        font-size: 14px;
    }

    .label-short {
        display: flex;
        overflow: hidden;
        max-width: 85px;
    }
}
