.error-view-container {
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    flex-direction: column;
    background: #2c2a29;
    color: #b5b2af;

    .top {
        background: #3f3d3c;
        color: #e0ddd9;
        flex: 0 0 auto;
        padding: 8px 24px 8px 24px;
        width: 100%;
        line-height: normal;
        display: flex;
        align-items: center;

        span {
            color: rgb(248, 124, 40);
            font-weight: 600;
            font-size: large;

            &:nth-child(2) {
                font-size: medium;
                flex: 1 1 auto;
                margin: 0 8px;
            }

        }

        button {
            flex: 0 0 auto;
            border-radius: 4px;
            border: 1px solid rgb(248, 124, 40);
            color: rgb(248, 124, 40);
            background: transparent;
            padding: 4px 8px;
            transition: all 0.3s;

            &:hover {
                background: rgb(248, 124, 40);
                color: #2c2a29;
            }
        }
    }

    .error-info {
        padding: 24px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 1 1 auto;
        width: 100%;

        .error-box {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            // flex: 1 1 auto;
            min-height: 65px;
            max-height: 300px;

            &:first-child {
                min-height: unset;
            }

            span {
                font-weight: 600;
            }
        }
    }

    pre.stack-trace {
        overflow: auto;
        flex: 1 1 auto;
    }
}

.error-fallback-ui-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: #2c2a29;
    color: #b5b2af;

    button {
        flex: 0 0 auto;
        border-radius: 4px;
        border: 1px solid rgb(248, 124, 40);
        color: rgb(248, 124, 40);
        background: transparent;
        padding: 4px 8px;
        transition: all 0.3s;

        &:hover {
            background: rgb(248, 124, 40);
            color: #2c2a29;
        }
    }
}