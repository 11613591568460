.pf-date-picker {
    
    .basic-menu.calendar-dropdown {
        color: #141414;
        &:hover {
            svg.rdp-dropdown_icon {
                fill: #18715e;
            }
        }
        ::after {
            content: none;
        }
    
        .dropdown-toggle, 
        .btn {
            padding: 0;
        }
    
        .dropdown-menu.show {
            font-size: 16px;
        }
        
        .dropdown-item,
        button {
            &:hover,
            &.show {
                background: #e7edff;
                border: 1px solid #e7edff;
                color: inherit;
            }
        }

        .dropdown-item {
            padding: 4px 8px;
            font-weight: 400;
        }

        button:hover {
            transition: all 0.3s;
            border: 1px solid #e7edff;
        }

    }
}