.base-modal {
	.modal-dialog {
		width: fit-content;

		@media print {
			width: 100%;
			height: 100%;
			margin: 0;
		}

		.modal-content {
			@media print {
				height: 100%;
				border: none;
				border-radius: 0;
			}

			.modal-header {
				align-items: flex-start;

				.modal-header-container {
					display: flex;
					align-items: center;
					gap: 10px;
					padding-top: 5px;
					width: 100%;

					.modal-icon {
						display: inline-flex;
						justify-content: center;
						width: 57px;
						height: 44px;
					}

					.modal-title-row {
						display: flex;
						align-items: center;
						gap: 1em;
					}

					.modal-title {
						height: 28px;
						font-size: 18px;
						font-weight: 600;
						letter-spacing: normal;
						color: #101828;
					}

					.modal-subtitle {
						display: inline-flex;
						gap: 5px;
						height: 20px;
						font-size: 14px;
						font-weight: normal;
						letter-spacing: normal;
						color: #4f4e4e;

						&.highlight {
							color: var(--color-green-sea, rgb(22, 112, 92));
						}
					}

					.modal-state {
						height: 20px;
						font-size: 14px;
						font-weight: normal;
						letter-spacing: normal;
						margin: 0 10px;
					}

					.actions-section {
						@media print {
							display: none;
						}

						flex: 1 1 auto;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						padding-right: 5px;

						.action-item {
							margin: 0 6px;

							&.disabled {
								opacity: 50%;
							}

							svg {
								height: 32px;
								max-width: 52px;
							}

							.basic-menu {
                                .icon-base {
                                    fill: var(--color-green-sea, rgb(22, 112, 92));
                                    stroke: var(--color-green-sea, rgb(22, 112, 92));
                                }
								button {
									&:hover,
                                    &.show {
										background-color: transparent;
										border: 1px solid transparent;
										color: inherit;

										.icon-base {
											fill: var(--color-green-sea, rgb(22, 112, 92));
        									stroke: var(--color-green-sea, rgb(22, 112, 92));
										}
									}
								}
							}
						}
					}
				}

				.btn-close {
					@media print {
						display: none;
					}

					background-size: 13px;
					padding: 14px;
				}
			}

			.modal-footer {
				@media print {
					display: none;
				}

				padding: 16px;

				.button-container {
					min-width: 210px;
					text-align: center;

					&.close-button {
						padding: 10px 18px;
						border-radius: 8px;
						box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
						border: solid 1px #d0d5dd;
						background-color: #fff;
						color: #18715e;
						font-size: 16px;

						&.data-changed {
							color: white;
							border-radius: 8px;
							box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
							border: solid 1px #7cdae1;
							background-color: #18715e;
						}

						&:hover:not(:disabled, .disabled) {
							border: solid 1px #18715e;
						}
					}

					&.command-button {
						min-width: 235px;
						height: 45.6px;
						margin-right: auto;
						gap: 10px;
						padding: 6px 10px;
						border: solid 1px #18715e;
						border-radius: 8px;
						box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
						font-size: 16px;
						line-height: 1.5;
						font-weight: 600;
						color: #18715e;

						&:hover:not(:disabled, .disabled) {
							background-color: #18715e;
							color: white;
						}
					}

                    &:disabled, &.disabled {
                        border-color: var(--button-disabled);
                        opacity: 1;
                        color: #d0d5dd;
                    }
				}
			}
		}
	}
}
