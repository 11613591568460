.page-top-bar {
    padding: var(--padding-bar, 10px 16px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;

    button {
        border: solid 1px rgba(0, 100, 78, 0.2);
        border-radius: 6px;

        .button-label {
            border-color: rgba(0, 100, 78, 0.2);
            padding: 5px 8px;
        }

        &:hover {
            border: 1px solid var(--primary-light, gray);
            background-color: var(--primary-light, gray);

            .button-label {
                border-color: var(--bs-btn-hover-color, white);
            }
        }
    }

    .top-bar-right {
        display: flex;

        .filter-controller {
            margin: 0 16px;
        }
    }

    .breadcrumbs-wrapper {
        display: flex;
        align-items: center;
        flex: 0 0 auto;

        span:first-child {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: rgba(79, 78, 78, 0.8);
            text-transform: uppercase;
        }
    }
}